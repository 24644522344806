/* METROPOLIS */
/*
Name	Weight
Thin, Hairline	100
Extra Light, Ultra Light	200
Light	300
Normal, Regular	400
Medium	500
Semi Bold, Demi Bold	600
Bold	700
Extra Bold, Ultra Bold	800
Black, Heavy	900
*/

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-Thin.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-ExtraLight.otf') format('opentype');
	font-weight: 200;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-SemiBold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-ExtraBold.otf') format('opentype');
	font-weight: 800;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-Black.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-ThinItalic.otf') format('opentype');
	font-weight: 100;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-ExtraLightItalic.otf') format('opentype');
	font-weight: 200;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-LightItalic.otf') format('opentype');
	font-weight: 300;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-RegularItalic.otf') format('opentype');
	font-weight: 400;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-MediumItalic.otf') format('opentype');
	font-weight: 500;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-SemiBoldItalic.otf') format('opentype');
	font-weight: 600;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-BoldItalic.otf') format('opentype');
	font-weight: 700;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-ExtraBoldItalic.otf') format('opentype');
	font-weight: 800;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: 'Metropolis';
	src: url('./Metropolis-BlackItalic.otf') format('opentype');
	font-weight: 900;
	font-style: italic;
	font-display: auto;
}
